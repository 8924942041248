export const ROLE_ADMIN = 1;
export const ROLE_KOPRAL = 2;
export const ROLE_SERSAN = 3;
export const ROLE_MAYOR = 4;
export const ROLE_PRO_BUSINESS = 5;
export const ROLE_BASIC = 6;
export const PLATFROM_FACEBOOK = 'facebook';
export const PLATFROM_INSTAGRAM = 'instagram';
export const PLATFROM_TWITTER = 'twitter';
export const PLATFROM_TIKTOK = 'tiktok';
export const PLATFROM_YOUTUBE = 'youtube';
export const apiKey = `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`;
