import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import { BeatLoader, ClipLoader } from "react-spinners";
import http from "utils/http";
import { apiKey } from "../../../../utils/const";
const { Configuration, OpenAIApi } = require("openai");

export default function IgGpt() {
  const [total, setTotal] = useState(2);
  const [caption, setCaption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const [counter, setCounter] = useState("");
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [theme, setTheme] = useState("");
  const [tension, setTension] = useState("");
  const [tone, setTone] = useState("");
  const [maxTotal, setMaxTotal] = useState(1);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    if (!total || !caption) {
      setError("All fields are required");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      let mediaFilename = null;

      if (selectedFile) {
        // Upload the file to the first API endpoint
        const uploadFormData = new FormData();
        uploadFormData.append("file", selectedFile);

        const uploadResponse = await http.post(
          "/upload/minio",
          uploadFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Check if the file upload was successful
        if (uploadResponse.status === 200) {
          mediaFilename = uploadResponse.data.data.filename;
        } else {
          throw new Error("File upload failed");
        }
      }
      const data = JSON.stringify({ caption: caption, media: mediaFilename });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "128",
        data: data,
      });

      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Blast successfully created",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Server busy,blast was not created",
      });
    }

    setIsLoading(false);
  };

  function generateCaptionGpt() {
    /*
        1,000 tokens is about 750 words
    */

    setIsGenerate(true);
    const configuration = new Configuration({
      apiKey: apiKey,
    });
    const openai = new OpenAIApi(configuration);

    new Promise(async (resolve, reject) => {
      const chat_completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content:
              "You will generate any caption and return in Bahasa. You will not make another response except the caption. Don't use any emoticons in your responses.",
          },
          {
            role: "user",
            content: `Buatkan ${total} caption maksimal 30 kata untuk twitter tentang ${theme}. Caption harus ${tension} dan menggunakan bahasa yang ${tone}. Buatkan dalam bentuk array.`,
          },
        ],
        max_tokens: 3056,
      });

      let captionRes = chat_completion.data.choices[0].message.content;
      captionRes = captionRes.replace("'", "").replace("/", " / ");
      resolve(captionRes);
    })
      .then((captionData) => {
        let jsonCaption;
        try {
          jsonCaption = JSON.parse(captionData);
        } catch (err) {
          jsonCaption = captionData.trim().split("\n");
          jsonCaption = jsonCaption.map((response) => {
            const withoutNumber = response.replace(/^\d+\.\s/, "");
            const withoutQuotes = withoutNumber.replace(/"([^"]+)"/g, "$1");
            return withoutQuotes;
          });

          jsonCaption = JSON.parse(jsonCaption);

          if (jsonCaption.length < total) generateCaptionGpt();
        }

        if (jsonCaption.length > total) {
          jsonCaption = jsonCaption.slice(0, total);
        }
        setCaption([...jsonCaption]);
      })
      .catch((err) => {
        generateCaptionGpt();
      });
  }

  function countDown() {
    const sec = 15;
    setCounter(sec);
    const counterInterval = setInterval(() => {
      setCounter((currState) => {
        const currCounter = currState - 1;
        if (currCounter === 0) clearInterval(counterInterval);
        return currCounter;
      });
    }, 1000);
  }

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("instagram");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if(activeCount.length > 0){
      setMaxTotal(activeCount[0].amount);
    }else{
      setMaxTotal(0);
    }

  };

  useEffect(() => {
    fetchAccountsTotal();
  }, []);

  useEffect(() => {
    if (caption.length > 0) {
      setIsGenerate(false);
      countDown();
    }
  }, [caption.length > 0 ? caption : ""]);

  useEffect(() => {
    setCaption([]);
  }, [theme]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <SoftBox
            color="white"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            md={6}
          >
            <SoftBox p={2}>
              <SoftTypography variant="h5" fontWeight="bold" mb={3}>
                Form Post With AI
              </SoftTypography>
              <form onSubmit={handleSubmit}>
                <SoftBox pl={2} mb={2}>
                  <SoftTypography fontWeight="bold" fontSize={18} mb={1}>
                    Total
                  </SoftTypography>
                  <SoftInput
                    type="number"
                    placeholder="Total"
                    defaultValue={total}
                    onBlur={(e) => {
                      const totalInput = parseInt(e.target.value)
                      const maxGenerate = 50

                      if(maxTotal > totalInput){
                        if(totalInput > maxGenerate){
                          e.target.value = maxGenerate
                        }
                      }else{
                        if(maxTotal > maxGenerate){
                          e.target.value = maxGenerate
                        }else{
                          e.target.value = maxTotal
                        }
                      }

                      if(totalInput <= 0){
                        e.target.value = 2
                      }
                    }}
                    onChange={(e) => setTotal(e.target.value)}
                  />
                </SoftBox>
                <SoftBox pl={2} mb={2}>
                  <SoftTypography fontWeight="bold" fontSize={18} mb={1}>
                    Theme / Topics / Context
                  </SoftTypography>
                  <SoftInput
                    placeholder="Theme / Topics / Context"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                  />
                  <SoftTypography fontSize={12} mt={1}>
                    eg. tentang sewa perumahan mahal. <br />
                    eg. tentang sepak bola.
                  </SoftTypography>
                </SoftBox>
                <SoftBox pl={2} mb={2}>
                  <SoftTypography fontWeight="bold" fontSize={18} mb={1}>
                    Argument
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: "negatif", label: "Negative" },
                      { value: "positif", label: "Positive" },
                    ]}
                    onChange={(e) => {
                      setTension(e.value);
                    }}
                  />
                </SoftBox>
                <SoftBox pl={2} mb={2}>
                  <SoftTypography fontWeight="bold" fontSize={18} mb={1}>
                    Tone
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: "kaku", label: "Formal" },
                      { value: "sarkas", label: "Sarcasm" },
                      {
                        value: "santai. Boleh menggunakan kata-kata slang",
                        label: "Casual",
                      },
                    ]}
                    onChange={(e) => {
                      setTone(e.value);
                    }}
                  />
                </SoftBox>
                {!counter ? (
                  <SoftButton
                    disabled={isGenerate}
                    variant="contained"
                    color="info"
                    onClick={() => generateCaptionGpt()}
                    style={{ marginLeft: "15px", marginBottom: "10px " }}
                  >
                    <>
                      {!isGenerate ? (
                        "Generate"
                      ) : (
                        <BeatLoader color="#21D4FD" />
                      )}
                    </>
                  </SoftButton>
                ) : (
                  <SoftButton
                    disabled={true}
                    variant="contained"
                    color="info"
                    style={{ marginLeft: "15px", marginBottom: "10px " }}
                  >
                    <>{counter}</>
                  </SoftButton>
                )}
                {caption.length > 0 &&
                  caption.map((val, idx) => (
                    <SoftTypography fontSize={12} key={val} marginLeft={2}>
                      {idx + 1}. {val}
                    </SoftTypography>
                  ))}
                <SoftBox mt={2} mb={3} h={2} marginLeft={2}>
                  <div>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                  {error && (
                    <SoftTypography variant="body2" color="error" mb={3}>
                      {error}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftButton
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="info"
                  mt={2}
                  style={{ marginLeft: "15px" }}
                >
                  {isLoading ? <ClipLoader size={20} /> : "Blast"}
                </SoftButton>
                {error && (
                  <SoftTypography variant="body2" color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </form>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </>
  );
}
