import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import http from "utils/http";
import { ClipLoader } from "react-spinners";

export default function Follow() {
  const [total, setTotal] = useState("");
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [maxTotal, setMaxTotal] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!total || !link) {
      setError("All fields are required");
      return;
    }

    if (total < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Total can't less than 1!!",
      });
      return;
    } else if (total > maxTotal) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Cannot exceed ${maxTotal}!`,
      });
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      const data = JSON.stringify({ link });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "190",
        data: data,
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your blast has been created successfully",
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Server busy,blast was not created",
      });
    }

    setIsLoading(false);
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("facebook");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if(activeCount.length > 0){
      setMaxTotal(activeCount[0].amount);
    }else{
      setMaxTotal(0);
    }

  };

  useEffect(() => {
    fetchAccountsTotal();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        <SoftBox
          color="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          md={6}
        >
          <SoftBox p={2}>
            <SoftTypography variant="h5" fontWeight="bold" mb={3}>
              Form Follow
            </SoftTypography>
            <form onSubmit={handleSubmit}>
              <SoftBox mb={3}>
                <SoftInput
                  placeholder="Link eg. https://www.facebook.com/john.doe"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput
                  placeholder="Total"
                  onBlur={(e) => {
                    const totalInput = parseInt(e.target.value)
                    const maxGenerate = 400

                    if(maxTotal > maxGenerate){
                      if(totalInput >= maxGenerate || isNaN(totalInput)){
                        e.target.value = maxGenerate
                        setTotal(maxGenerate)
                      }
                    }else if(maxTotal < maxGenerate || isNaN(totalInput)){
                      if(totalInput >= maxTotal){
                        e.target.value = maxTotal
                        setTotal(maxTotal)
                      }
                    }
                  }}
                  onChange={(e) => setTotal(e.target.value)}
                />
                {error && (
                  <SoftTypography style={{ fontSize: 12 }} color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="info"
              >
                {isLoading ? <ClipLoader size={20} /> : "Blast"}
              </SoftButton>
            </form>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
