/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React example components
import TablePagination from "examples/Tables/TablePagination";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import http from "utils/http";

const CreateModal = ({
  route,
  modalCreateOpen,
  setModalCreateOpen,
  createAccount,
  isLoading,
}) => {
  return (
    <>
      <Modal
        open={modalCreateOpen}
        onClose={() => setModalCreateOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            boxShadow: 24,
            p: 4,
            minWidth: 500,
            borderRadius: "10px",
          }}
        >
          <SoftTypography id="modal-modal-title" variant="h5" component="h2">
            Create {route} account
          </SoftTypography>
          <SoftBox sx={{ mt: 2 }}>
            <form onSubmit={(e) => createAccount(e)} id="create-account-form">
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Username <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: johndoe"
                  name="username"
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Password <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: Rhino12"
                  name="password"
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Location</label>
                <SoftInput
                  type="text"
                  name="location"
                  // placeholder="Location"
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftButton
                  color="info"
                  size="small"
                  type="submit"
                  sx={{ mr: 1 }}
                  form="create-account-form"
                  disabled={isLoading}
                >
                  {isLoading ? <BeatLoader color="#21D4FD" /> : "Submit"}
                </SoftButton>
                <SoftButton
                  color="secondary"
                  size="small"
                  onClick={() => setModalCreateOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
};

const EditModal = ({
  route,
  modalEditOpen,
  setModalEditOpen,
  editAccount,
  detailAccount,
  isLoading,
}) => {
  return (
    <>
      <Modal
        open={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            boxShadow: 24,
            p: 4,
            minWidth: 500,
            borderRadius: "10px",
          }}
        >
          <SoftTypography id="modal-modal-title" variant="h5" component="h2">
            Edit {route} account
          </SoftTypography>
          <SoftBox sx={{ mt: 2 }}>
            <form onSubmit={(e) => editAccount(e)} id="edit-account-form">
              <input type="hidden" name="id" defaultValue={detailAccount.id} />
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Username <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: john doe"
                  name="username"
                  defaultValue={detailAccount.username}
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Password <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: Rhino12"
                  name="password"
                  defaultValue={detailAccount.password}
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Location</label>
                <SoftInput
                  type="text"
                  // placeholder="Location"
                  name="location"
                  defaultValue={detailAccount.location}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftButton
                  color="info"
                  size="small"
                  type="submit"
                  sx={{ mr: 1 }}
                  disabled={isLoading}
                  form="edit-account-form"
                >
                  {isLoading ? <BeatLoader color="#21D4FD" /> : "Submit"}
                </SoftButton>
                <SoftButton
                  color="secondary"
                  size="small"
                  onClick={() => setModalEditOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
};

function SocialMediaAccount() {
  const route = useLocation().pathname.split("/")[2]
  const navigate = useNavigate();
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [detailAccount, setDetailAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await http.get("/auth/verify");
        const role = currentUser.data.data.role.name;

        if (role !== "Admin") {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You don't have any access to this resources!",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/dashboard");
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchCurrentUser();
  }, []);

  const showEditModal = async (accountID) => {
    try {
      const response = await http.get(`/${route}/${accountID}`);
      setDetailAccount(response.data.data);
      setModalEditOpen(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const createAccount = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      setIsLoading(true);
      await http.post(`/${route}`, {
        username: formData.get("username"),
        password: formData.get("password"),
        location: formData.get("location"),
      });
      setIsLoading(false);
      await Swal.fire({
        icon: "success",
        title: `${route} account created!`,
      });
      setModalCreateOpen(false);
      getAccounts();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const editAccount = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      setIsLoading(true);
      await http.put(`/${route}/${formData.get("id")}`, {
        username: formData.get("username"),
        password: formData.get("password"),
        location: formData.get("location"),
      });
      setIsLoading(true);
      await Swal.fire({
        icon: "success",
        title: `${route} account created!`,
      });
      setModalEditOpen(false);
      getAccounts();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const deleteAccount = async (accountID) => {
    const confirm = await Swal.fire({
      icon: "info",
      title: "You sure?",
      showCancelButton: true,
    });
    if (confirm.isConfirmed) {
      try {
        await http.delete(`/${route}/${accountID}`);
        await Swal.fire({
          icon: "success",
          title: `${route} account deleted!`,
        });
        getAccounts();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  };

  const getAccounts = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/${route}?page=${page.onPage}`);
      const totalPage = Math.ceil(response.data.data.total / 10);

      setAccounts(response.data.data.results);
      setPage((curr) => ({
        onPage: totalPage < curr.onPage ? 1 : curr.onPage,
        totalData: response.data.data.total,
        totalPage: totalPage,
      }));
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  useEffect(() => {
    getAccounts();
  }, [page.onPage]);

  useEffect(() => {
    setPage({
      onPage: 1,
      totalData: 0,
      totalPage: 0,
    });

    getAccounts();
  }, [route]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateModal
        route={route}
        modalCreateOpen={modalCreateOpen}
        setModalCreateOpen={setModalCreateOpen}
        createAccount={createAccount}
        isLoading={isLoading}
      />
      <EditModal
        modalEditOpen={modalEditOpen}
        setModalEditOpen={setModalEditOpen}
        editAccount={editAccount}
        detailAccount={detailAccount}
        isLoading={isLoading}
      />
      <SoftBox mb={2}>
        <TablePagination
          columns={[
            { name: "Username", align: "left" },
            { name: "Password", align: "left" },
            { name: "Action", align: "left" },
          ]}
          rows={accounts.map((row, index) => ({
            Username: <div style={isLoading ? { width: "100px", height: "20px" } : {}} className={isLoading && "loading-skeleton"}>{!isLoading ? row.username : "\u00a0"}</div>,
            Password: <div style={isLoading ? { width: "100px", height: "20px" } : {}} className={isLoading && "loading-skeleton"}>{!isLoading ? row.password : "\u00a0"}</div>,
            Action: (
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Grid item xs={6}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => showEditModal(row.id)}
                  >
                    Edit
                  </SoftButton>
                </Grid>
                <Grid item xs={6}>
                  <SoftButton
                    color="error"
                    size="small"
                    onClick={() => deleteAccount(row.id)}
                  >
                    Delete
                  </SoftButton>
                </Grid>
              </Grid>
            ),
          }))}
          page={page}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleCreate={() => setModalCreateOpen(true)}
        />
      </SoftBox>
    </DashboardLayout>
  );
}

export default SocialMediaAccount;
